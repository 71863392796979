import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore/lite';

export const app = initializeApp({
  apiKey: "AIzaSyBpmNzlQtABl30QFpjQw8iSnKTZHI7nf0I",
  authDomain: "ligatech-acessotech.firebaseapp.com",
  databaseURL: "https://ligatech-acessotech.firebaseio.com",
  projectId: "ligatech-acessotech",
  storageBucket: "ligatech-acessotech.appspot.com",
  messagingSenderId: "359593116769",
  appId: "1:359593116769:web:2d711baf622bb62d319620",
  measurementId: "G-HYYEW0HN5X"
})

export const db = getFirestore(app)
