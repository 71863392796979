import React, { useState, useEffect } from "react"
import { getFirestore, collection, query, where, doc, getDoc, getDocs, getCountFromServer } from "firebase/firestore"
import { Page, PageContent } from "app/components"
import { useParams } from "react-router-dom"
import { Card, Col, Divider, List, Row, Tag, Input, Statistic, Space } from "antd"
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Pie, PieConfig } from '@ant-design/plots';
import type { Event, Sector, SectorGroupCounter, SectorGroup, Ticket } from "../../types"

const { Search } = Input;

export interface TotalPieProps extends PieConfig {}

export const TotalPie: React.FC<TotalPieProps> = (props) => {
  return (
    <div>
      <Pie
        {...props}
        appendPadding={10}
        angleField={'value'}
        colorField={'type'}
        radius={0.85}
        startAngle={Math.PI}
        endAngle={Math.PI * 3}
        label={{
          type: 'inner',
          offset: '-30%',
          content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
          style: {
            fontSize: 14,
            textAlign: 'center',
          },
        }}
        interactions={[
          {
            type: 'element-active',
          },
        ]}
      />
    </div>
  )
}
