import * as React from "react"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import { Loading } from "app/components"
import { useAuthState } from "app/services/firebase"
import * as Pages from "app/pages"

const authRouter = createBrowserRouter([
  { path: "/", element: <Pages.LoginPage /> },
]);

const dashboardRouter = createBrowserRouter([
  { path: "/", element: <Pages.HomePage /> },
  { path: "/events/:eventId", element: <Pages.EventPage /> },
  { path: "/signout", element: <Pages.SignOutPage /> },
]);

export const MainNavigation: React.FunctionComponent<{}> = (props) => {
  const [loading, currentUser] = useAuthState()

  if (loading) {
    return <Loading />
  }

  if (currentUser) {
    return <RouterProvider router={dashboardRouter} />
  } else {
    return <RouterProvider router={authRouter} />
  }
}
