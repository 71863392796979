import React from "react"
import { ConfigProvider } from "antd"
import { MainNavigation } from "./app/navigation/navigation";
import { SizeType } from "antd/lib/config-provider/SizeContext"
import pt_BR from 'antd/es/locale/pt_BR'
import 'antd/dist/reset.css'
import "styles/scss/app.scss"

export const App: React.FC = (props) => {
  const config = {
    componentSize: "medium" as SizeType,
    locale: pt_BR
  }

  return (
    <ConfigProvider {...config}>
      <MainNavigation />
    </ConfigProvider>
  )
}
