import React, { useState, useEffect } from "react"
import { getFirestore, collection, query, where, doc, getDoc, getDocs, getCountFromServer, orderBy } from "firebase/firestore"
import { Page, PageContent } from "app/components"
import { useParams } from "react-router-dom"
import { ReloadOutlined } from '@ant-design/icons';
import { Bar, Column, Gauge, Plot } from '@ant-design/plots';
import { Button, Tooltip, Space } from 'antd';
import type { Event, Sector, SectorGroupCounter, SectorGroup, Ticket } from "../../types"

export interface SectorsGroupsProps {
  eventId: string
}

interface SectorsGroupsGraphProps {
  data: SectorGroupCounter[]
}

const SectorsGroupsGraph: React.FC<SectorsGroupsGraphProps> = (props) => {
  return (
    <Column
      data={props.data}
      isStack={false}
      isPercent={true}
      xField={'name'}
      yField={'value'}
      seriesField={'type'}
      xAxis={{
        label: {
            autoRotate: true
        },
        tickLine: null,
      }}
      legend={{ position: 'top-left' }}
      label={{
        position: 'middle',
        formatter: (data) => {
          return `${Math.round(data.total * data.value).toFixed(0)}`
        }
      }}
      // color={['#a29bfe', '#0984e3']}
      color={['#95afc0', '#535c68']}
      interactions={[
        {
          type: 'active-region',
          enable: false,
        }
      ]}
      connectedArea={{
        style: (oldStyle, element) => {
          return {
            fill: 'rgba(0, 0, 0, 0.75)',
            stroke: oldStyle.fill,
            lineWidth: 0.5,
          }
        }
      }}
    />
  )
}

export const SectorsGroups: React.FC<SectorsGroupsProps> = (props) => {
  const [sectorsGroupsCounters, setSectorsGroupsCounters] = useState<SectorGroupCounter[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const countSectorsGroups = React.useCallback(async () => {
    setLoading(true)
    const eventId = props.eventId
    const sectorsGroupsRef = collection(getFirestore(), "events", eventId, "sectors")
    const sectorsGroupsSnapshot = await getDocs(sectorsGroupsRef);
    const _sectorsGroups: SectorGroupCounter[] = []
    const result = sectorsGroupsSnapshot.docs.map(async (item) => {
      const data = item.data()

      const ticketsBaseRef = collection(getFirestore(), "events", eventId, "tickets")
      const ticketsRef = query(ticketsBaseRef, where("isValidTicket", "==", true))
      const ticketsTotalRef = query(ticketsRef, where("setor", "==", item.id))
      const ticketsTotalSnapshot = await getCountFromServer(ticketsTotalRef);
      
      const ticketsEntrouQuery = query(ticketsBaseRef, where("entrou", "==", true), where("setor", "==", item.id))
      const ticketsEntrouSnapshot = await getCountFromServer(ticketsEntrouQuery);

    //   const total = ticketsTotalSnapshot.data().count
    const total = ticketsTotalSnapshot.data().count
    const inside = ticketsEntrouSnapshot.data().count
    const outside = total - inside

      // const inside = Math.floor(Math.random() * 6000);
      // const outside = Math.floor(Math.random() * 2000);
      // const outside = 100 - inside;
      // const outside = 100 - inside;

      // console.log("outside, inside, total:", data.groupId, outside, inside, outside + inside)

      _sectorsGroups.push({
        eventId: data.eventId,
        groupId: data.groupId,
        name: data.name,
        type: "Não Entrou",
        value: outside,
        total: total
      }, {
        eventId: data.eventId,
        groupId: data.groupId,
        name: data.name,
        type: "Entrou",
        value: inside,
        total: total
      })

      return item
    })

    Promise.all(result).then(() => {
      setSectorsGroupsCounters(_sectorsGroups)
    }).finally(() => setLoading(false))

  }, [props.eventId])

  useEffect(() => {
    countSectorsGroups()
  }, [countSectorsGroups])

  return (
    <div style={{
      position: 'relative',
    }}>
      <SectorsGroupsGraph data={sectorsGroupsCounters} />
      {/* <Button
        style={{
          position: "absolute",
          right: 0,
          zIndex: 9999,
          top: -10,
        }}
        shape={'circle'}
        icon={<ReloadOutlined loop={loading} />}
        type="dashed"
        onClick={countSectorsGroups}
        loading={loading}
        title="Recarregar"
      /> */}
    </div>
  )
}
