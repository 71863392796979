
export type ErrorCode =
  | "auth/account-exists-with-different-credential"
  | "auth/app-deleted"
  | "auth/app-not-authorized"
  | "auth/argument-error"
  | "auth/credential-already-in-use"
  | "auth/email-already-in-use"
  | "auth/invalid-api-key"
  | "auth/invalid-tenant-id"
  | "auth/invalid-user-token"
  | "auth/network-request-failed"
  | "auth/operation-not-allowed"
  | "auth/requires-recent-login"
  | "auth/too-many-requests"
  | "auth/unauthorized-domain"
  | "auth/user-disabled"
  | "auth/user-not-found"
  | "auth/user-token-expired"
  | "auth/weak-password"
  | "auth/web-storage-unsupported"
  | "auth/wrong-password"

export const $message = (errorCode: ErrorCode) => {
  const errorsMap = {
    "auth/email-already-in-use": "este email já está em uso",
    "auth/wrong-password": "a senha está errada",
    "auth/user-not-found": "conta não encontrada",
    "auth/weak-password": "use pelo menos 6 caracteres para a senha",
    "auth/too-many-requests": "muitas tentativas, aguarde um pouco",
    "auth/account-exists-with-different-credential": "auth/account-exists-with-different-credential",
    "auth/app-deleted": "auth/app-deleted",
    "auth/app-not-authorized": "auth/app-not-authorized",
    "auth/argument-error": "auth/argument-error",
    "auth/credential-already-in-use": "auth/credential-already-in-use",
    "auth/invalid-api-key": "auth/invalid-api-key",
    "auth/invalid-tenant-id": "auth/invalid-tenant-id",
    "auth/invalid-user-token": "auth/invalid-user-token",
    "auth/network-request-failed": "auth/network-request-failed",
    "auth/operation-not-allowed": "auth/operation-not-allowed",
    "auth/requires-recent-login": "auth/requires-recent-login",
    "auth/unauthorized-domain": "auth/unauthorized-domain",
    "auth/user-disabled": "auth/user-disabled",
    "auth/user-token-expired": "auth/user-token-expired",
    "auth/web-storage-unsupported": "auth/web-storage-unsupported",
  }

  if (errorCode === undefined) {
    return "ocorreu um erro, tente novamente"
  } else {
    if (errorCode in errorsMap) {
      return errorsMap[errorCode]
    } else {
      return errorCode
    }
  }
}
