import React, { useState, useEffect } from "react"
import { getFirestore, collection, query, where, doc, getDoc, getDocs, getCountFromServer } from "firebase/firestore"
import { Page, PageContent } from "app/components"
import { useParams } from "react-router-dom"
import { ReloadOutlined } from '@ant-design/icons';
import { Bar, Column, Gauge, Plot } from '@ant-design/plots';
import { Button, Table, Tooltip, Space } from 'antd';
import { sortBy, prop } from 'ramda';
import type { ColumnsType } from 'antd/es/table';
import type { Event, Sector, SectorGroupCounter, SectorGroupRow, SectorGroup, Ticket } from "../../types"


const columns: ColumnsType<SectorGroupRow> = [
  {
    title: 'Setor',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a.name.length - b.name.length,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Entrou',
    dataIndex: 'inside',
    key: 'inside',
    sorter: (a, b) => a.inside - b.inside,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Não Entrou',
    dataIndex: 'outside',
    key: 'outside',
    sorter: (a, b) => a.outside - b.outside,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    sorter: (a, b) => a.total - b.total,
    sortDirections: ['ascend', 'descend'],
  },
];

export interface SectorsGroupsTableProps {
  eventId: string
}

export const SectorsGroupsTable: React.FC<SectorsGroupsTableProps> = (props) => {
  const [sectorsGroupsRows, setSectorsGroupsRows] = useState<SectorGroupRow[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const countSectorsGroups = React.useCallback(async () => {
    setLoading(true)
    const eventId = props.eventId
    const sectorsGroupsRef = collection(getFirestore(), "events", eventId, "sectors")
    const sectorsGroupsSnapshot = await getDocs(sectorsGroupsRef);

    const _sectorsGroupsRows: SectorGroupRow[] = []

    const result = sectorsGroupsSnapshot.docs.map(async (item) => {
      const data = item.data()

      const ticketsBaseRef = collection(getFirestore(), "events", eventId, "tickets")
      const ticketsRef = query(ticketsBaseRef, where("isValidTicket", "==", true))
    //   const ticketsRef = collection(getFirestore(), "events", eventId, "tickets")
    //   let ticketsTotalRef;
    //   if (data.sectors.length > 30) {
    //     ticketsTotalRef = query(ticketsRef, where("setor", ">=", data.minSector), where("setor", "<=", data.maxSector))
    //   } else {
    //     ticketsTotalRef = query(ticketsRef, where("setor", "in", data.sectors))
    //   }
      const ticketsTotalRef = query(ticketsRef, where("setor", "==", item.id))

      const ticketsEntrouBase = collection(getFirestore(), "events", eventId, "tickets")
      const ticketsEntrouRef = query(ticketsEntrouBase, where("entrou", "==", true), where("setor", "==", item.id))
      const ticketsEntrouSnapshot = await getCountFromServer(ticketsEntrouRef);
      const ticketsTotalSnapshot = await getCountFromServer(ticketsTotalRef);

    //   const total = data?.capacidade
      const total = ticketsTotalSnapshot.data().count
      const inside = ticketsEntrouSnapshot.data().count
      const outside = total - inside

      _sectorsGroupsRows.push({
        eventId: data.eventId,
        groupId: data.groupId,
        name: data.name,
        inside,
        outside,
        total
      })

      return item
    })

    Promise.all(result).then(() => {
      const sortByName =  sortBy(prop('name'))
      const _sorted = sortByName(_sectorsGroupsRows);
      setSectorsGroupsRows(_sorted)
    }).finally(() => setLoading(false))

  }, [props.eventId])

  useEffect(() => {
    countSectorsGroups()
  }, [countSectorsGroups])

  return (
    <div style={{
      position: 'relative',
    }}>
      <Table
        columns={columns}
        dataSource={sectorsGroupsRows}
        pagination={{
          position: [],
          pageSize: 100
        }}
      />
    </div>
  )
}
