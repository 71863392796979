import { useState, useEffect } from "react"
// import { firebase } from "./firebase"
import { getAuth, onAuthStateChanged } from "firebase/auth"

/**
 * Tenta olhar isso depois:
 * https://dev.to/bmcmahen/using-firebase-with-react-hooks-21ap
 */

export const useCurrentUser = () => {
  const [user] = useState(getAuth().currentUser)
  return user
}

export const useAuthState = () => {
  const [user, setUser] = useState(undefined)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), user => {
      setUser(user as any)
      setLoading(false)
    })
    return () => {
      unsubscribe()
    }
  })

  return [loading, user]
}
