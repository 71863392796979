import * as React from "react"
import { useState } from "react"
import { UserOutlined, LockOutlined } from "@ant-design/icons"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { $message } from "app/services/errors";
import { Row, Col, Layout } from "antd"
import { Form, Input, Button } from "antd"


// Phone Number Auth
// https://gist.github.com/joshuasprow/0b1505ce8b8ca52975023bb57dbd37c6


const { Content, Sider } = Layout

interface Validation {
  status?: "success" | "validating" | "error",
  help?: string
}

interface FormSchema {
  email: Validation,
  password: Validation
}

const initialValidations: FormSchema = {
  email: { status: undefined, help: undefined },
  password: { status: undefined, help: undefined }
}

export interface LoginPageProps {}

export const LoginPage: React.FunctionComponent<LoginPageProps> = props => {
  const [submitting, setSubmitting] = useState(false)
  const [form] = Form.useForm()
  const [validations, setValidations] = useState(initialValidations)

  const onFinish = (values: any) => {
    setSubmitting(true)
    setValidations(initialValidations)

    signInWithEmailAndPassword(getAuth(), values.email, values.password).then(() => {
      console.log("Login feito com sucesso.")
    }).catch(err => {
      if (err.code === "auth/wrong-password") {
        setValidations({
          email: {
            status: undefined,
            help: undefined
          },
          password: {
            status: "error",
            help: "Senha inválida"
          }
        })
      } else {
        console.error(err)
        setValidations({
          email: {
            status: "error",
            help: $message(err.code)
          },
          password: {
            status: undefined,
            help: undefined
          }
        })
      }
    }).finally(() => setSubmitting(false))
  }

  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          left: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        width="40%"
        breakpoint="lg"
        collapsedWidth="0"
      >
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <img alt="Logo horizontal" src="/images/logo-dark.svg" height="24" />
        </div>
      </Sider>
      <Layout>
        <Content>
          <section style={{ maxWidth: 400, margin: '0 auto' }}>
            <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
              <Col span={18}>
                <Row justify="center" style={{ paddingBottom: '4rem' }}>
                  <Col lg={0}>
                    <img alt="Logo horizontal" src="/images/logo-light.svg" height="24" />
                  </Col>
                </Row>
                <h2>Fazer login</h2>
                <Form
                  form={form}
                  name="normal_login"
                  className="login-form"
                  initialValues={{}}
                  onFinish={onFinish}
                >
                  <Form.Item
                    name="email"
                    rules={[{ required: true, message: "Por favor, digite o seu email." }]}
                    validateStatus={validations.email.status}
                    help={validations.email.help}
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: "Por favor, digite a sua senha." }]}
                    validateStatus={validations.password.status}
                    help={validations.password.help}
                  >
                    <Input
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Senha"
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button style={{ marginTop: 20 }} type="primary" shape="round" htmlType="submit" className="login-form-button" loading={submitting}>
                      Entrar
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </section>
        </Content>
      </Layout>
    </Layout>
  )
}
