import React, { useState, useEffect } from "react"
import { getFirestore, collection, getDocs, query, where, orderBy } from "firebase/firestore"
import { Table } from "antd"
import type { ColumnsType } from 'antd/es/table';
import { Page, PageContent } from "app/components"
import { Link } from "react-router-dom"
import type { Event } from "../types"
import moment, { now } from "moment";

const columns: ColumnsType<Event> = [
  {
    title: 'ID',
    dataIndex: 'eventId',
    key: 'eventId',
    render: (text) => <Link to={`/events/${text}`}>{text}</Link>,
  },
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Descrição',
    dataIndex: 'description',
    key: 'description',
    responsive: ['md'],
  },
  {
    title: 'Data',
    dataIndex: 'eventDate',
    key: 'eventDate',
    responsive: ['md'],
    render: (text) => <span>{moment(text.toDate()).format('DD/MM/YYYY [às] hh:mm')}</span>
  },
  {
    title: 'Inicia em',
    dataIndex: 'startsAt',
    key: 'startsAt',
    responsive: ['md'],
    render: (text) => <span>{moment(text.toDate()).format('DD/MM/YYYY [às] hh:mm')}</span>
  },
  {
    title: 'Encerra em',
    dataIndex: 'endsAt',
    key: 'endsAt',
    responsive: ['md'],
    render: (text) => <span>{moment(text.toDate()).format('DD/MM/YYYY [às] hh:mm')}</span>
  },
]

export interface HomePageProps {}

export const HomePage: React.FC<HomePageProps> = props => {
  const [events, setEvents] = useState<Event[]>()

  useEffect(() => {
    (async () => {
      const eventsRef = collection(getFirestore(), "events")
      const q = query(
        eventsRef,
        where("clients", "array-contains-any", [
            "cdd43eb86d994dc994b587daadda4a7b", "3279bc38e0884c4a88b370c5a1e7e400"
            // "19233ed1df154424b87f79581cf368bd", "28863b63978742aa85bdea01f64f5e5d"
        ]),
        orderBy("startsAt", 'desc')
      );
      // const q = query(eventsRef, where("eventId", "in", ["de9772a093c3486498831ee9d37b63c5", "1888b8ac-a310-48ba-bc19-c5237e2642d7"]));

      const querySnapshot = await getDocs(q);
      const items = querySnapshot.docs.map((item) => {
        const data = item.data()
        return {
          eventId: item.id,
          archived: data.archived === true,
          name: data.name,
          description: data.description,
          clients: data.clients,
          eventDate: data.eventDate,
          startsAt: data.startsAt ? data.startsAt : data.eventDate,
          endsAt: data.endsAt ? data.endsAt : data.eventDate,
        }
      })

      console.log("items:", items)
      const events_ = items.filter(e => {
        const day = 1000 * 60 * 60 * 24
        const inFiveDays = now() + (5*day)
        const lastFiveDays = now() - (5*day)
        const eventDate: number = e.eventDate.toDate()
        const showIt = lastFiveDays <= eventDate && eventDate <= inFiveDays
        console.log("showIt:", showIt, "lastFiveDays:", lastFiveDays, "inFiveDays:", inFiveDays)
        return showIt
      })
      console.log("events:", events_)
      setEvents(events_)
    })()
  }, [])

  return (
    <Page>
      <PageContent>
        <Table
          columns={columns}
          dataSource={events}
          pagination={{
            pageSize: 100
          }}
        />
      </PageContent>
    </Page>
  )
}
