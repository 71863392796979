import * as React from "react"
import { Layout } from "antd"

export interface LoadingProps {
}

export const Loading = (props: LoadingProps) => {
  return (
    <Layout>
      <Layout.Header />
      <Layout.Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 64px)' }}>
        <img alt="Imagem demonstrando o carregamento" src="/illustrations/loading_illustration.svg" style={{ height: 120 }} />
      </Layout.Content>
    </Layout>
  )
}
