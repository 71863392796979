import * as React from "react"
import { Skeleton, Layout } from "antd"

export interface PageContentProps {
  children: React.ReactNode,
  loading?: boolean,
}

export const PageContent = (props: PageContentProps) => {
  return (
    <div>
      <Layout className="site-layout-content">
        {props.loading
          ? <Skeleton
            loading={props.loading}
            active
          />
          : props.children
        }
      </Layout>
    </div>
  )
}
