import * as React from "react"
import { Link } from "react-router-dom"
import { Dropdown, Space, Row, Col, Layout } from "antd"
import type { MenuProps } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useCurrentUser } from "app/services";

const { Header, Content, Footer } = Layout;

const items: MenuProps['items'] = [
  {
    key: 'signout',
    danger: true,
    label: <Link to="/signout">Sair</Link>,
  },
];

export interface PageProps {
  children: React.ReactNode,
  noSidebar?: boolean
}

export const Page = (props: PageProps) => {
  const currentUser = useCurrentUser()

  return (
    <Layout>
      <Header className="lt-header">
        <Row className="container-fluid" justify="space-between">
          <Col>
            <Link to="/">
              <img alt="Logo do Ligatech Arena" src="/images/logo-dark.svg" style={{ width: 120 }} />
            </Link>
          </Col>
          <Col>
            <Dropdown menu={{ items }} placement="bottomRight" arrow>
              <Space style={{ cursor: "pointer", color: "white" }}>
                <span>
                  {currentUser && (currentUser.displayName || currentUser.email)}
                </span>
                <DownOutlined />
              </Space>
            </Dropdown>
          </Col>
        </Row>
      </Header>
      <Content className="container-fluid site-layout">
        {props.children}
      </Content>
      <Footer style={{ textAlign: 'center' }}>Ligatech Serviços LTDA © 2023</Footer>
    </Layout>
  )
}
