import React, { useState, useEffect } from "react"
import { getFirestore, collection, query, where, doc, getDoc, getDocs, getCountFromServer } from "firebase/firestore"
import { SectorsGroups, SectorsGroupsTable, TotalGauge, TotalPie, Page, PageContent } from "app/components"
import { useParams } from "react-router-dom"
import { Card, Col, Divider, List, Space, Statistic, Row, Tag, Button } from "antd"
import { ReloadOutlined } from '@ant-design/icons';
import type { Event, Sector } from "../types"

export interface EventPageProps {}

export const EventPage: React.FC<EventPageProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [reloading, setReloading] = useState<boolean>(false)
  const [event, setEvent] = useState<Event>()
  const [sectors, setSectors] = useState<Sector[]>()
  const [totalTickets, setTotalTickets] = useState<number>()
  const [totalTicketsEntrou, setTotalTicketsEntrou] = useState<number>()
  const [error, setError] = useState<string | null>(null)
  const params = useParams()
  const [keyCount, setKeyCount] = useState<number>(0)

  const getEvent = React.useCallback(async () => {
    try {
      const eventsRef = collection(getFirestore(), "events")
      const eventRef = doc(eventsRef, params.eventId)
      const eventSnapshot = await getDoc(eventRef)

      if (eventSnapshot.exists()) {
        // obtendo dados do evento
        const _event = eventSnapshot.data()
        setLoading(false)
        setEvent({
          eventId: _event.eventId,
          archived: _event.archived,
          name: _event.name,
          description: _event.description,
          clients: _event.clients,
          eventDate: _event.eventDate,
          startsAt: _event.startsAt ? _event.startsAt : _event.eventDate,
          endsAt: _event.endsAt ? _event.endsAt : _event.eventDate,
        })
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.error("err:", error)
      setLoading(false)
      setError((error as unknown as Error).toString())
    }
  }, [params.eventId])

  const countTotals = React.useCallback(async () => {
    try {
      if (event) {
        // obtendo dados de setores
        const sectorsRef = collection(getFirestore(), "events", event.eventId, "sectors")
        const sectorsSnapshot = await getDocs(sectorsRef);
        const _sectors = sectorsSnapshot.docs.map((item) => {
          const data = item.data()
          return {
            eventId: data.eventId,
            name: data.name,
            sectorId: item.id,
          }
        })
        setSectors(_sectors)

        // obtendo dados de ingressos
        const ticketsRefBase = collection(getFirestore(), "events", event.eventId, "tickets")
        const ticketsRef = query(ticketsRefBase, where("isValidTicket", "==", true))
        // const ticketsRef = ticketsRefBase
        const ticketsSnapshot = await getCountFromServer(ticketsRef);
        // const ticketsEntrouRef = query(ticketsRefBase, where("isValidTicket", "==", true), where("entrou", "==", true))
        const ticketsEntrouRef = query(ticketsRefBase, where("entrou", "==", true))
        const ticketsEntrouSnapshot = await getCountFromServer(ticketsEntrouRef);

        setTotalTickets(ticketsSnapshot.data().count)
        setTotalTicketsEntrou(ticketsEntrouSnapshot.data().count)

        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.error("err:", error)
      setLoading(false)
      setError((error as unknown as Error).toString())
    }
  }, [event])

  const onReload = React.useCallback(() => {
    setReloading(true)
    setKeyCount(keyCount + 1)
    countTotals().finally(() => setReloading(false))
  }, [countTotals, keyCount])

  useEffect(() => {
    if (loading) getEvent()
  }, [getEvent, loading, onReload])

  useEffect(() => {
    if (event) countTotals()
  }, [countTotals, event])

  useEffect(() => {
    const interval = setInterval(() => {
      onReload()
    }, 1000 * 60);
    return () => clearInterval(interval);
  }, [onReload]);

  return (
    <Page>
      <PageContent loading={loading}>
        { event ? (
          <div>
            <Row>
              <Col span={24}>
                <span>
                  <h2 style={{ display: 'inline' }}>{ event.name }</h2>
                  <p style={{ marginLeft: 10, display: 'inline' }}>ID: <Tag color="magenta">{ event.eventId }</Tag></p>
                  <Button type="dashed" shape="circle" onClick={onReload} loading={reloading} icon={<ReloadOutlined />} />
                </span>
                <p style={{ marginBottom: 15 }}>Descrição: { event.description }</p>
              </Col>
            </Row>

            <Divider />

            <Row gutter={16}>
              <Col span={24}>
                <SectorsGroups key={`key-${keyCount}`} eventId={event.eventId} />
              </Col>
            </Row>

            <Row>
                <Col xs={8} sm={8} md={8} lg={8} xxl={8}>
                    { (totalTickets && totalTicketsEntrou) && (
                    <>
                        <TotalGauge totalTickets={totalTickets} totalTicketsEntrou={totalTicketsEntrou} />
                        {/* <div style={{ marginTop: 100 }}>
                            <TotalPie
                            data={[
                                {
                                type: "Entrou",
                                value: totalTicketsEntrou
                                },
                                {
                                type: "Não Entrou",
                                value: totalTickets - totalTicketsEntrou
                                }
                            ]}
                            angleField={'value'}
                            colorField={'type'}
                            color={['#95afc0', '#535c68']}
                            />
                        </div> */}
                    </>
                    ) }
                </Col>
                <Col span={16}>
                    <div style={{ marginTop: 100 }}>
                    <SectorsGroupsTable key={`key-${keyCount}`} eventId={event.eventId} />
                    </div>
                </Col>
            </Row>

            <Divider />

            {/* <Row gutter={16}>
              <Col span={6}>
                <div
                  id="scrollableDiv"
                  style={{
                    height: 400,
                    overflow: 'auto',
                    padding: '0 16px',
                    border: '1px solid rgba(140, 140, 140, 0.35)',
                    // border: '1px solid #f0f0f0',
                    // borderRadius: '8px'
                  }}
                >
                  <List
                    dataSource={sectors}
                    renderItem={(item) => (
                      <List.Item key={item.sectorId}>
                        <List.Item.Meta
                          title={item.name}
                          description={`ID: ${item.sectorId}`}
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </Col>
              <Col offset={3} span={6}>
                <Space size="middle">
                  <Card>
                    <Statistic
                      title="Total de Setores"
                      value={sectors?.length}
                      precision={0}
                      valueStyle={{ color: '#3f8600' }}
                    />
                  </Card>
                  <Card>
                    <Statistic
                      title="Total de Ingressos"
                      value={totalTickets}
                      groupSeparator="."
                      precision={0}
                      valueStyle={{ color: '#3f8600' }}
                    />
                  </Card>
                  <Card>
                    <Statistic
                      title="Total de Acessos"
                      groupSeparator="."
                      value={totalTicketsEntrou}
                      precision={0}
                      valueStyle={{ color: '#3f8600' }}
                    />
                  </Card>
                </Space>
              </Col>
            </Row> */}
          </div>
        ) : (
          <>
            <p>{ error }</p>
            { error ? (
              <p>{ error }</p>
            ) : (
              <p>evento não existe</p>
            ) }
          </>
        )}
      </PageContent>
    </Page>
  )
}
