import * as React from "react"
import { useEffect } from "react"
import { Layout } from "antd"
import { useNavigate } from "react-router"
import { getAuth, signOut } from "firebase/auth"


export const SignOutPage: React.FC = props => {
  const navigate = useNavigate()

  useEffect(() => {
    signOut(getAuth()).then(() => navigate("/"))
  }, [navigate])

  return (
    <Layout>
      <Layout.Content style={{ margin: '24px 16px 0' }}>
        <h2>Redirecionando...</h2>
      </Layout.Content>
    </Layout>
  )
}
