import * as React from "react"
import { useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { Layout, Menu } from "antd"
import * as LtIcon from "app/components/icons/icons"

const { Sider } = Layout

export interface AppMenuProps { }

export interface LabelProps {
  children: React.ReactNode
}

export const AppMenu = (props: AppMenuProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const navigate = useNavigate();
  const location = useLocation()
  const mainMenu = location.pathname.split("/")[2]

  const Label = (props: LabelProps) => {
    return <span className="menu-label">{props.children}</span>
  }

  return (
    <Sider
      breakpoint="lg"
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
      }}
    >
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[mainMenu]}
        className="lt-side-menu-inline"
      // defaultOpenKeys={['sub1']}
      >
        <Menu.Item key="dashboard" onClick={() => navigate(`/`)}>
          <LtIcon.Home />
          <Label>Início</Label>
        </Menu.Item>
        {/* <Menu.Item key="jogos" onClick={() => navigate(`/jogos/`)}>
          <LtIcon.SoccerBall />
          <Label>Jogos</Label>
        </Menu.Item>
        <Menu.Item key="campeonatos" onClick={() => navigate(`/campeonatos/`)}>
          <LtIcon.ChampionshipCup />
          <Label>Campeonatos</Label>
        </Menu.Item>
        <Menu.Item key="clientes" onClick={() => navigate(`/clientes/`)}>
          <LtIcon.User />
          <Label>Clientes</Label>
        </Menu.Item>
        <Menu.Item key="ingressos" onClick={() => navigate(`/ingressos/`)}>
          <LtIcon.Ticket />
          <Label>Ingressos</Label>
        </Menu.Item>
        <Menu.Item key="moderninhas" onClick={() => navigate(`/moderninhas/`)}>
          <LtIcon.PaymentMachine />
          <Label>Moderninhas</Label>
        </Menu.Item>
        <Menu.Item key="clubes" onClick={() => navigate(`/clubes/`)}>
          <LtIcon.ClubShirt />
          <Label>Clubes</Label>
        </Menu.Item>
        <Menu.Item key="pdvs" onClick={() => navigate(`/pdvs/`)}>
          <LtIcon.PosDesktop />
          <Label>PDV's</Label>
        </Menu.Item>
        <Menu.Item key="site-de-venda" onClick={() => navigate(`/site-de-venda/`)}>
          <LtIcon.Ecommerce />
          <Label>Sites de Vendas</Label>
        </Menu.Item>
        <Menu.Item key="locais" onClick={() => navigate(`/locais/`)}>
          <LtIcon.Stadium />
          <Label>Locais</Label>
        </Menu.Item>
        <Menu.Item key="staff" onClick={() => navigate(`/staff/`)}>
          <LtIcon.Staff />
          <Label>Staff</Label>
        </Menu.Item>
        <Menu.Item key="usuarios" onClick={() => navigate(`/usuarios/`)}>
          <LtIcon.User />
          <Label>Usuários</Label>
        </Menu.Item> */}
      </Menu>
    </Sider>
  )
}
