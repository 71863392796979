import React, { useState, useEffect } from "react"
import { getFirestore, collection, query, where, doc, getDoc, getDocs, getCountFromServer } from "firebase/firestore"
import { Page, PageContent } from "app/components"
import { useParams } from "react-router-dom"
import { Card, Col, Divider, List, Row, Tag, Input, Statistic, Space } from "antd"
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Bar, Column, Gauge } from '@ant-design/plots';
import type { Event, Sector, SectorGroupCounter, SectorGroup, Ticket } from "../../types"

const { Search } = Input;

export interface TotalGaugeProps {
  totalTickets: number
  totalTicketsEntrou: number
}

export const TotalGauge: React.FC<TotalGaugeProps> = (props) => {
  return (
    <div>
      <Gauge
        style={{
          position: 'relative',
          top: -65
        }}
        percent={props.totalTicketsEntrou/props.totalTickets} 
        radius={0.75}
        range={{
          color: '#2d3436',
          width: 12,
        }}
        startAngle={Math.PI}
        endAngle={2 * Math.PI}
        indicator={{
          pointer: {
            style: {
              stroke: '#D0D0D0',
            },
          },
          pin: {
            style: {
              stroke: '#D0D0D0',
            },
          },
        }}
        statistic={{
          content: {
            offsetY: 90,
            formatter: (datum) => `Entrou: ${(datum?.percent * 100).toFixed(0)}% Vendido: ${props.totalTickets}`,
            style: {
              fontSize: '18px',
              color: '#4B535E',
            },
          },
          title: {
            offsetY: 60,
            style: {
              fontSize: '24px',
              color: '#4B535E',
            },
            formatter: (datum) => `Entrou: ${props.totalTicketsEntrou} Faltam: ${props.totalTickets - props.totalTicketsEntrou}`
          },
        }}
        gaugeStyle={{
          lineCap: 'round',
        }}
      />
    </div>
  )
}
